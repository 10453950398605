import React from 'react';
import { Section, Wrapper } from '@riseart/layout';
import { errors as ERRORS_ENUM } from 'Enum';
import { CmsViewModule } from 'shared_hocs/cms/views/Module';
import { LayoutDefault, COLOR_THEMES } from 'shared_components/layouts/Default';
import { HomeTrending } from 'shared_components/pages/home/sections/trending/Trending';
import { ArtReviews } from 'shared_components/arts/reviews/Reviews';

import {
  cmsNoMarginTop,
  cmsNoMarginBottom,
} from 'shared_components/cms/modules/common/styles.less';

/**
 * HomePage
 */
const HomePage = () => (
  <LayoutDefault
    theme={COLOR_THEMES.TRANSPARENT}
    transparent
    layoutQueryErrorHandler={{
      customOptions: {
        // Home page requires custom error handling for page layout
        // queries as it is almost entirely build by CMS fragments,
        // so in this case it should log all errors from renderCmsView query
        errorFilter: (errorList = []) =>
          errorList.filter((error) => error && error.path && error.path[0] === 'renderCmsView'),
        error: { handler: ERRORS_ENUM.handlers.ERROR_PAGE },
      },
    }}
  >
    <CmsViewModule placeholder="cmsHomeCarousel" />
    <CmsViewModule placeholder="cmsHomeFilters" />
    <CmsViewModule placeholder="cmsHomeArts1" moduleProps={{ disableHeadingTags: true }} />
    <CmsViewModule placeholder="cmsHomeArts2" moduleProps={{ disableHeadingTags: true }} />
    <CmsViewModule placeholder="cmsHomeBanner1" />
    <CmsViewModule placeholder="cmsHomeArtists" moduleProps={{ cardTitleTag: 'span' }} />
    <CmsViewModule placeholder="cmsHomeArticles" moduleProps={{ titleTag: 'h3' }} />
    <CmsViewModule placeholder="cmsHomeCollections" />
    <HomeTrending disableHeadingTags />
    <Section>
      <Wrapper>
        <ArtReviews />
      </Wrapper>
    </Section>
    <CmsViewModule placeholder="cmsHomeBanner2" moduleProps={{ className: cmsNoMarginBottom }} />
    <CmsViewModule placeholder="cmsHomeReasons" moduleProps={{ className: cmsNoMarginTop }} />
    <CmsViewModule placeholder="cmsHomePress" skipFallback />
  </LayoutDefault>
);

export default HomePage;
